import * as React from 'react';
import {api, ApiContext} from '../api';
import {selectDefaultSorter} from '../common/sorters/sorters';
import {PARAM_KEY_ORDER, PARAM_KEY_SORT} from '../consts';
import {FieldValueProps} from '../field/FieldValue';
import {Actions} from '../types/Action';
import {Field} from '../types/Field';
import {Params} from '../types/Params';
import {ResourceDetails} from '../types/ResourceDetails';
import {ResourceList} from '../types/ResourceList';
import {widgetProps} from '../widgets/common';
import {ReadOnlyTableWidget} from '../widgets/ReadOnlyTableWidget';
import {TableWidget} from '../widgets/TableWidget';

type Props = FieldValueProps & {
  item: ResourceDetails;
  actions: Actions;
};

export function TableField(props: Props) {
  if (!props.field.table) {
    return null;
  }

  if (props.readOnly) {
    return (
      <div style={{marginTop: '0.2em', marginBottom: '0.2em'}}>
        <ReadOnlyTableWidget
          onLoad={() => {
            return loadList(props.ctx, props.field, props.item);
          }}
          {...props.field.table}
          timestamp={props.timestamp}
        />
      </div>
    );
  }

  return (
    <TableWidget
      ctx={props.ctx}
      itemType={props.field.itemType!}
      item={props.item}
      table={props.field.table}
      onChange={(v) => {
        props.onChange(v);
      }}
      readOnly={!props.field.editable}
    />
  );
}

const loadList = async (
  ctx: ApiContext,
  field: Field,
  details: ResourceDetails,
): Promise<ResourceList | null> => {
  if (!field.table) {
    return null;
  }

  const itemType = field.itemType;

  if (!itemType) {
    return null;
  }

  const fid = field.table.keyFieldId || 'id';
  const key = details[fid];

  const optParams = widgetProps<Params>(field, 'params', {});
  const paramsFieldId = widgetProps<string>(field, 'params_field_id', '');

  const params = {
    ...optParams,
    ...field.table.params,
    ...details[paramsFieldId],
    [field.table.refFieldId]: key,
  };

  if (field.table.view) {
    params['_view'] = field.table.view;
  }

  const sorter = selectDefaultSorter(field.table.sorters);

  if (sorter) {
    params[PARAM_KEY_SORT] = sorter.id;
    params[PARAM_KEY_ORDER] = sorter.defaultOrder;
  }

  return await api.list(ctx, itemType, params);
};
