import {ActionTarget} from '../../types/Action';
import {Field} from '../../types/Field';
import {ResourceDetails} from '../../types/ResourceDetails';
import {Schema} from '../../types/Schema';
import {widgetProps} from '../../widgets/common';

export function useActionTarget(
  field: Field,
  schema: Schema,
  item: ResourceDetails,
): ActionTarget {
  const schemaId = useSchemaId(field, schema, item);
  const fieldId = widgetProps<string>(field, 'key_field_id', 'id');
  const resId = item[fieldId];

  return {
    schemaId,
    resId,
  };
}

function useSchemaId(
  field: Field,
  schema: Schema,
  item: ResourceDetails,
): string {
  if (item.schema_id) {
    return item.schema_id;
  }

  return widgetProps<string>(field, 'schema_id', schema.id);
}
