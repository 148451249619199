import React, {CSSProperties, useState} from 'react';
import styled from 'styled-components';
import {useApiContext} from '../../api';
import {ItemDetailsDialog} from '../../common/dialogs/ItemDetailsDialog';
import {
  buildTargetItemPropsMap,
  TargetItemProps,
} from '../../fields/hooks/useItemProps';
import {Actions} from '../../types/Action';
import {ResourceDetails} from '../../types/ResourceDetails';
import {Item} from './Item';

export type ItemRenderer = (
  item: ResourceDetails,
  index: number,
) => JSX.Element | null;

type Props = {
  targets: TargetItemProps[];
  items: ResourceDetails[];
  actions: Actions;
  itemRenderer?: ItemRenderer;
  styles?: {
    item?: CSSProperties;
  };
};

export function Items(props: Props): JSX.Element | null {
  const [itemId, setItemId] = useState<string>();
  const [schemaId, setSchemaId] = useState<string>();
  const [shown, setShown] = useState<boolean>(false);
  const ctx = useApiContext();

  if (props.items.length === 0) {
    return null;
  }

  const targetMap = buildTargetItemPropsMap(props.targets);

  const renderer: ItemRenderer =
    props.itemRenderer ||
    function (item: ResourceDetails, index: number) {
      const schemaId = item.schema_id;
      const target = targetMap[schemaId];

      return (
        <ItemWrapper
          {...props}
          key={`item-${index}`}
          item={item}
          onClick={async () => {
            await setItemId(item.id);

            if (schemaId) {
              setSchemaId(schemaId);
            }

            setShown(true);
          }}
          style={props.styles?.item}
          nameFieldId={target?.nameFieldId}
          iconColor={target?.itemSchema.iconColor}
        />
      );
    };

  return (
    <Container>
      {props.items.map(renderer)}
      <ItemDetailsDialog
        ctx={ctx}
        shown={shown}
        schemaId={schemaId}
        resId={itemId}
        onClose={() => {
          setShown(false);
        }}
        actions={props.actions}
        minWidth={'400px'}
      />
    </Container>
  );
}

type ItemProps = {
  item: ResourceDetails;
  nameFieldId: string;
  iconColor?: string;
  onClick?: () => void;
  style?: CSSProperties;
};

function ItemWrapper(props: ItemProps): JSX.Element {
  return (
    <ItemContainer style={props.style} onClick={props.onClick}>
      <Item {...props} />
    </ItemContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const ItemContainer = styled.div`
  max-width: 100%;
  cursor: pointer;
`;
